function scrollPage() {
    const body = document.body;
    const nav = document.getElementById("header");
    const navheight = nav.getBoundingClientRect();
    const menu = document.querySelector(".header .menu");
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;

    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;

        if (currentScroll <= navheight) {
            body.classList.remove(scrollUp);
            return;
        }
        if (currentScroll > navheight) {
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (
                currentScroll < lastScroll &&
                body.classList.contains(scrollDown)
            ) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
        }
        lastScroll = currentScroll;
    });
};
export default scrollPage;
import Bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import Shuffle from 'shufflejs';
import AOS from 'aos';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import hcSticky from 'hc-sticky';
import scrollPage from './nav-stick'
import JSShare from "js-share";

Swiper.use([Navigation, Pagination, Autoplay]);

function App() {
    "use strict";

    /**
     * mquery
     */
    var mdQuery = window.matchMedia("(min-width: 992px)")

    /**
     * Easy isotope news
     */
    let shufCont = document.getElementById('isonumb')
    if (shufCont) {

        const shuffleInstance = new Shuffle(shufCont, {
            itemSelector: '.itemNumb',
            sizer: ".grid-sizer",
            buffer: 1,
            isCentered: true,
        })
    }


    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {

        if (all) {
            select(el, all).forEach(e => e.addEventListener(type, listener))
        } else {
            select(el, all).addEventListener(type, listener)
        }

    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }


    /**
     * Tools slider
     */
    new Swiper('.testimonials-slider', {
        speed: 600,
        loop: true,
        perPage: 1,
        centeredSlides: true,
        autoplay: {
           delay: 6000,
           disableOnInteraction: false
        },
        slidesPerView: 1,
        breakpoints: {
            1200: {
                slidesPerView: "auto",
                spaceBetween: 40
            }
        },
        pagination: {
            el: '.swiper-pagination-test',
            clickable: true,
            type: 'custom',
            renderCustom: function(swiper, current, total) {
                var out = ''
                for (let i = 1; i < total + 1; i++) {
                    if (i == current) {
                        out = out + '<span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex=' + i + ' role="button" aria-label="Go to slide ' + i + 1 + '"></span>';
                    } else {
                        out = out + '<span class="swiper-pagination-bullet" tabindex=' + i + ' role="button" aria-label="Go to slide ' + i + 1 + '"></span>';
                    }
                };
                return out;
            },
        },

    });

    /**
     * header stick
     */
    scrollPage();
    let header = select('.header')

    //** stockynav */
    function stickNav(mdQuery) {


        // If media query matches

        var Sticky = new hcSticky('.header', {
            stickTo: 'body',
            resizeDebounce: 500,
            mobileFirst: true,
            responsive: {
                0: {
                    disable: true
                },
                992: {
                    disable: false
                }
            },
            onStart: function() {
                header.classList.add('stickheader')
            },
            onStop: function() {
                header.classList.remove('stickheader')
            }
        })




    }


    stickNav(mdQuery)
    mdQuery.addListener(stickNav)
        ////////////

    var swipertools = new Swiper('.toolsSwiper', {
        direction: 'horizontal',
        noSwipingClass: 'noswipe'
    });
    /**
     * Clients Slider
     */


    new Swiper('.usertest-slider', {
        speed: 600,
        loop: true,
        perPage: 1,
        centeredSlides: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        slidesPerView: 1,
        breakpoints: {
            900: {
                slidesPerView: 2,
                perPage: 1,
                centeredSlides: false,
            },
            1200: {
                slidesPerView: 3,
                perPage: 1,
                spaceBetween: 40,
                centeredSlides: true,
            }
        },
        pagination: {
            el: '.swiper-pagination-test',
            type: 'bullets',
            clickable: true
        },
    });


    /*/



     /**
      * Navbar links active state on scroll


    on('click', ".nav-link span", function(e) {
        let aelem = this.parentNode;
        let gohref = aelem.getAttribute("href");
        location = gohref;
    })

    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
    }
    if (navbarlinks) {
        window.addEventListener('load', navbarlinksActive)
        onscroll(document, navbarlinksActive)
    }
*/
    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
        let header = select('#header')
        let offset = header.offsetHeight



        let elementPos = select(el).offsetTop
        window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
        })
    }



    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add('active')
            } else {
                backtotop.classList.remove('active')
            }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
    }

    /**
     if (navbarlinks) {
         /**
          * Mobile nav toggle
          */
    /// on('click', '.mobile-nav-toggle', function (e) {
    ///     select('#navbar').classList.toggle('navbar-mobile')
    ///     this.classList.toggle('bi-list')
    ///     this.classList.toggle('bi-x')
    /// })

    /**
     * Mobile nav dropdowns activate
     */
    //on('click', '.navbar .dropdown > a', function(e) {
    //    if (select('#navbar').classList.contains('navbar-mobile')) {
    //        e.preventDefault()
    //        this.nextElementSibling.classList.toggle('dropdown-active')
    //    }
    //}, true)

    /**
     * Scrool with ofset on links with a class name .scrollto

    on('click', '.scrollto', function(e) {
        if (select(this.hash)) {
            e.preventDefault()

            let navbar = select('#navbar')
            if (navbar.classList.contains('navbar-mobile')) {
                navbar.classList.remove('navbar-mobile')
                let navbarToggle = select('.mobile-nav-toggle')
                navbarToggle.classList.toggle('bi-list')
                navbarToggle.classList.toggle('bi-x')
            }
            scrollto(this.hash)
        }
    }, true)
}

    /**
     * share social app
     */
     var shareItems = document.querySelectorAll('.social_share');
     for (var i = 0; i < shareItems.length; i += 1) {
       shareItems[i].addEventListener('click', function share(e) {
         return JSShare.go(this);
       });
     }

    /*/

      /**
       * Animation on scroll
       */
    function aos_init() {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false
        });
    }
    window.addEventListener('load', () => {
        aos_init();
    });

    $(document).ready(function () {

        var inputc = $( "form input:checkbox" ) ;

        inputc.change(function() {
            if ($(this).is(':checked')) { $(this).parent().addClass('is-check');} else {$(this).parent().removeClass('is-check');}
        });
    });


};


export default App;
